<template>
	<div>
		<h1 class="main-title">Объёмы сборов</h1>
		<v-data-table
			:headers="pivotHeaders"
			:items="tabs"
			hide-default-footer
			dense
			@click:row="(item) => handleClick(item)"
		>
			<template v-slot:item.title="{ item }">
				<span>{{ item.title }}</span>
				<span v-if="item.name.includes('mobile')">
					<v-icon small>
						mdi-cellphone
					</v-icon>
				</span>
			</template>
			<template v-slot:item.ch="{ item }">
				<template v-if="calcCHWait(item.name) || calcPercent(item.name, 'prc_ch_finish')">
					<span>
						{{ calcCHWait(item.name) ? calcCHWait(item.name) : '-' }}
					</span>
					<b>
						{{ calcPercent(item.name, 'prc_ch_finish') ? `(${calcPercent(item.name, 'prc_ch_finish')}%)` : '' }}
					</b>
				</template>
				<span v-else>
					-
				</span>
			</template>
		</v-data-table>

		<br/>
		<br/>
		<v-progress-linear
			v-if="loading"
			indeterminate
			color="blue darken-2"
		/>
		<div
			v-else
			class="d-flex"
		>
			<v-chart :value="chartOptionsCountChAll" />
			<v-chart :value="chartOptionsCountIncomplete" />
		</div>
		<br/>
		<br/>

		<v-tabs v-model="tabId" grow>
			<v-tab v-for="item in tabs" :key="item.id">
				<span>{{ item.title }}</span>
				<span v-if="item.name.includes('mobile')">
					<v-icon small>
						mdi-cellphone
					</v-icon>
				</span>
			</v-tab>
		</v-tabs>
		<v-data-table
			class="save-table"
			:headers="headers"
			:items="tabItems"
			:loading="loading"
			:footer-props="{
				itemsPerPageOptions: [15, 25, 50, 100],
				itemsPerPageText: 'Строк на стр.'
			}"
			dense
			@click:row="(item) => goToProjects(item)"
		>
			<template v-slot:item.date="{ item }">
				{{ item.date.split('-').reverse().join('.') }}
			</template>
			<template v-slot:item.count_ch_all="{ item }">
				<span v-if="item.count_ch_all && item.count_ch_all != 0">
					{{ item.count_ch_all.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.count_out="{item}">
				<span v-if="typeof item.count_out === 'number'">
					{{ item.count_out.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.count_out_prc="{item}">
				<span>{{ getCountOutPrc(item) }}</span>
			</template>
			<template v-slot:item.count_ch_finish="{ item }">
				<span v-if="item.count_ch_finish && item.count_ch_finish != 0">
					{{ item.count_ch_finish.toLocaleString() }}
					<template v-if="item.count_ch_all && item.count_ch_all != 0 && (item.count_ch_finish > item.count_ch_all)">
						(<b>100</b>%)
					</template>
					<template v-else>
						(<b>{{
							Number.isInteger(item.prc_ch_finish)
								? item.prc_ch_finish
								: parseFloat(item.prc_ch_finish.toFixed(3))
						}}</b>%)
					</template>
				</span>
				<span v-else>-</span>
			</template>
			<template #item.count_incomplete_top15="{ item }">
				<span v-if="typeof item.count_incomplete_top15 === 'number'">
					{{ item.count_incomplete_top15.toLocaleString() }} ({{ calcCountIncompletePrc(item.count_incomplete_top15, item.count_ch_finish) }})
				</span>
				<span v-else>-</span>
			</template>
			<template #item.count_incomplete_top25="{ item }">
				<span v-if="typeof item.count_incomplete_top25 === 'number'">
					{{ item.count_incomplete_top25.toLocaleString() }} ({{ calcCountIncompletePrc(item.count_incomplete_top25, item.count_ch_finish) }})
				</span>
				<span v-else>-</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import chart from '@/components/chart'

export default {
	components: {
		'v-chart': chart
	},

	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'Cron - Proxy - SEO',
			pivotHeaders: [
				{
					text: 'Provider',
					value: 'title',
					align: 'start',
					sortable: false
				},
				{
					text: 'CH',
					value: 'ch',
					align: 'center',
					sortable: false
				}
			],
			headers: [
				{
					text: 'Date',
					value: 'date',
					align: 'center',
					sortable: false
				},
				{
					text: 'All (CH)',
					value: 'count_ch_all',
					align: 'center',
					sortable: false
				},
				{
					text: 'Запросы в Out',
					value: 'count_out',
					align: 'center',
					sortable: false
				},
				{
					text: '% запросов в Out',
					value: 'count_out_prc',
					align: 'center',
					sortable: false
				},
				{
					text: 'Недослепки топ-15',
					value: 'count_incomplete_top15',
					align: 'center',
					sortable: false
				},
				{
					text: 'Недослепки топ-25',
					value: 'count_incomplete_top25',
					align: 'center',
					sortable: false
				},
				{
					text: 'Done (CH)',
					value: 'count_ch_finish',
					align: 'center',
					sortable: false
				}
			],
			tabId: 0,
			currentTab: null,
			loading: true
		}
	},

	computed: {
		...mapState({
			savedData: state => state.save.savedData
		}),

		...mapGetters({
			tabs: 'save/filteredTabs',
			items: 'save/items'
		}),

		tabItems () {
			if (this.currentTab && this.items) {
				return this.items(this.currentTab)
			}
			return []
		},

		dates () {
			const today = new Date()
			const date_to = today.toISOString().split('T')[0]

			const ninetyDaysAgo = new Date()
			ninetyDaysAgo.setDate(today.getDate() - 90)
			const date_from = ninetyDaysAgo.toISOString().split('T')[0]

			return {
				date_from,
				date_to
			}
		},

		chartOptionsCountChAll () {
			return {
				...this.getChartOptionsBase('Динамика объёмов сбора'),
				series: this.prepareChartSeriesData('count_ch_all')
			}
		},

		chartOptionsCountIncomplete () {
			return {
				...this.getChartOptionsBase('Данные по недослепкам'),
				series: this.prepareChartSeriesData('count_incomplete_top15')
			}
		}
	},

	watch: {
		tabId: {
			handler (valId) {
				if (valId !== null) {
					this.currentTab = this.tabs.find((el) => el.id === valId).name
				}
			},
			immediate: false
		}
	},

	async mounted () {
		try {
			await this.getSavedData(this.dates)
			await this.getConfig()
		} catch (error) {
			this.$notify({ type: 'error', title: error })
		} finally {
			this.loading = false
			this.currentTab = this.tabs && this.tabs[0].name
		}
	},

	methods: {
		...mapActions({
			getSavedData: 'save/getSavedData',
			getConfig: 'save/getConfig'
		}),

		handleClick (value) {
			const item = { ...value }
			this.currentTab = item.name
			this.tabId = this.tabs.find((el) => el.name === item.name).id
		},

		goToProjects (item) {
			this.$router.push({
				path: '/data/projects/save',
				query: { date: item.date }
			})
		},

		calcCHWait (tab) {
			const items = this.items(tab)
			if (items && items.length) {
				const wait = items[0].count_ch_all - items[0].count_ch_finish
				return wait && !isNaN(wait) ? wait.toLocaleString() : null
			}
			return undefined
		},

		calcPercent (tab, property) {
			const items = this.items(tab)
			if (items && items.length) {
				return items[0][property] ? Number.isInteger(items[0][property]) ? 100 - items[0][property] : parseFloat((100 - items[0][property]).toFixed(2)) : null
			}
			return undefined
		},

		prepareChartSeriesData (metric) {
			if (this.savedData) {
				return Object
					.entries(this.savedData).filter(([key, value]) => key && value && key !== 'unknown')
					.map(([key, value]) => {
						return {
							name: key,
							data: value
								.map((item) => {
									if (item.date) {
										const dateMsec = this.transformDateToMs(item.date)
										return [dateMsec, item[metric]]
									}
								})
								.sort((a, b) => a[0] - b[0])
						}
					})
			}

			return []
		},

		transformDateToMs (date) {
			return new Date(date).getTime()
		},

		getCountOutPrc (data) {
			if (data) {
				const { count_ch_all, count_out } = data

				if (typeof count_ch_all === 'number' && typeof count_out === 'number' && count_ch_all !== 0) {
					return `${(count_out * 100 / count_ch_all).toLocaleString(undefined, { maximumFractionDigits: 5 })}%`
				}
			}

			return '-'
		},

		calcCountIncompletePrc (countIncomplete, countFinish) {
			if (typeof countFinish === 'number') {
				if (countFinish && countFinish) {
					return `${(countIncomplete / countFinish * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%`
				} else {
					return 0
				}
			}
			return '-'
		},

		getChartOptionsBase (title) {
			return {
				title: {
					align: 'left',
					style: {
						fontSize: '32px',
						fontWeight: 300
					},
					text: title
				},
				xAxis: {
					type: 'datetime',
					labels: {
						format: '{value:%d.%m.%Y}'
					}
				},
				yAxis: {
					opposite: false,
					title: { text: null },
					labels: {
						align: 'right',
						style: {
							color: '#bdbdbd',
							fontSize: '12px'
						}
					},
					gridLineDashStyle: 'ShortDash',
					gridLineColor: '#DEDEDE'
				}
			}
		}
	}
}
</script>

<style>
.main-title {
	margin: 20px 0;
}

.v-data-table.save-table > .v-data-table__wrapper > table > tbody > tr {
	cursor: pointer;
}
</style>
